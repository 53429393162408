import style from './ContactPanel.module.scss'
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import './hideRecaptchaBadge.scss';
import { Button, TextField } from "@mui/material";
import useExpirationBoolean from "../hooks/useExpirationBoolean";
import SvgPhoneIcon from '@mui/icons-material/Phone';
import SvgEmailIcon from '@mui/icons-material/Email';
import SvgLocationOnIcon from '@mui/icons-material/LocationOn';

const INITIAL_FORM_DATA = {
    name: '',
    email: '',
    message: '',
    _subject: `Novo sporočilo iz spletne strani ${window.location.hostname}.`,
    _template: 'table'
}

interface ContactPanelProps {
    address: string
    email: string
    phone: string
}

export default function ContactPanel(props: ContactPanelProps) {
    const [formData, setFormData] = useState<Record<string, string>>(INITIAL_FORM_DATA);
    const [showSuccess, setShowSuccess] = useExpirationBoolean(6000);
    const [showError, setShowError] = useExpirationBoolean(6000);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const formDataRef = useRef(formData);
    useEffect(() => {
        formDataRef.current = formData;
    }, [formData])

    const submitForm = (event: SyntheticEvent) => {
        event.preventDefault();
        setFormSubmitting(true);
        (recaptchaRef.current as any).execute();
    }
    const recaptchaRef = useRef();

    const sendMessage = () => {
        fetch(`https://formsubmit.co/ajax/${process.env.REACT_APP_FORMSUBMIT_IO_KEY}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formDataRef.current)
        }).then(response => response.json())
            .then(data => setShowSuccess())
            .catch(error => setShowError())
            .finally(() => {
                setFormData({...INITIAL_FORM_DATA})
                setFormSubmitting(false);
            });
        (recaptchaRef.current as any).reset();
    };

    return <div className={style.contactPanelContainer}>
        <div className={style.contactInfo}>
            <div className={style.contactInfoItem}>
                <b className={style.contactInfoLabel}>ADDRESS<SvgLocationOnIcon className={style.icon}/></b>
                <p className={style.contactInfoValue}>{props.address}</p>
            </div>
            <a href={`mailto:${props.email}`}
               target={"_blank"}
               className={style.contactInfoItem}>
                <b className={style.contactInfoLabel}>EMAIL<SvgEmailIcon className={style.icon}/></b>
                <p className={style.contactInfoValue}>{props.email}</p>
            </a>
            <a href={`tel:${props.phone}`} className={style.contactInfoItem}>
                <b className={style.contactInfoLabel}>PHONE<SvgPhoneIcon className={style.icon}/></b>
                <p className={style.contactInfoValue}>{props.phone}</p>
            </a>
        </div>
        <div className={style.contactForm}>
            <form onSubmit={submitForm} target="_blank">
                <div className={style.inputField}>
                    <TextField id="name"
                               name="name"
                               type="text"
                               label="Full Name"
                               value={formData.name}
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               variant="outlined"
                               margin="normal"
                               size="small"
                               fullWidth
                               required/>
                </div>
                <div className={style.inputField}>
                    <TextField id="email"
                               name="email"
                               type="email"
                               label="Email"
                               value={formData.email}
                               onChange={(e) => setFormData({...formData, email: e.target.value})}
                               variant="outlined"
                               margin="normal"
                               size="small"
                               fullWidth
                               required/>
                </div>
                <div className={style.inputField}>
                    <TextField
                        id="message"
                        name="message"
                        label="Your Message"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={(e) => setFormData({...formData, message: e.target.value})}
                        required
                    />
                </div>
                <div className={style.buttonContainer}>
                    <p className={style.recaptchaMessage}>This site is protected by reCAPTCHA and the Google <a
                        href="https://policies.google.com/privacy">Privacy Policy</a> and <a
                        href="https://policies.google.com/terms">Terms of Service</a> apply.</p>

                    <Button disabled={formSubmitting} variant="contained" type="submit">Pošlji sporočilo</Button>
                </div>
                <Recaptcha
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onResolved={sendMessage}/>
            </form>

            <div className={style.statusContainer}>
                <p style={{display: showSuccess ? 'block' : 'none'}}
                   className={style.success + " " + style.statusMessage}>
                    Sporočilo je bilo uspešno poslano.
                </p>
                <p style={{display: showError ? 'block' : 'none'}} className={style.error + " " + style.statusMessage}>
                    Žal je prišlo do napake pri oddaji sporočila.
                </p>
            </div>
        </div>
    </div>;
}
