import React from 'react';
import style from './App.module.scss';
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ServiceDetails from "./pages/ServiceDetails";
import Header from "./components/Header";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import TriangleSeparatorTop from "./components/TriangleSeparatorTop";
import NoRouteFound from "./pages/NoRouteFound";
import ScrollToTop from "./components/ScrollToTop";
import SanitarnaOprema from "./assets/sanitarna-oprema.jpeg";
import Keramika from "./assets/water-jet-cutting.webp";
import Armature from "./assets/dornbracht-armatura.jpeg";
import Welness from "./assets/welness.jpeg";

function App() {
    return (
        <div className={style.root}>
            <div className={style.pageContent}>
                <Header/>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/storitve/keramika"
                           element={<ServiceDetails title={"Keramika"}
                                                    thumbnail={Keramika}
                                                    description={"Kot prvi v Sloveniji se že od same iznajdbe nove tehnologije ukvarjamo s prodajo in razrezom ultra-tanke keramike, izdelane po najnovejši tehnologiji, ki omogoča debelino od 3mm ter velikost do 3000x1000mm. Poleg Kerlita (Plus), ki je oral ledino na področju ultra-tanke keramike, vam nudimo še produkte SlimTech, Laminam, Panaria in ZER0.3. Vsi se ponašajo z izjemnimi karakteristikami in so primerni tako za stenske obloge, kot za talne obloge, saj so lahko ojačani s »fiber-glass« strukturo. Zaradi izjemnih dimenzij in neverjetne lahkosti so primerni tudi za bolj zahtevno uporabo, na primer za fasade ali obloge na pohištvu.\n" +
                                                    "\n" +
                                                    "Nudimo tudi keramiko, izdelano po klasični tehnologiji, ki zaradi zadnjih izboljšav omogoča velikosti do 1200x1200mm pri debelini 4,7mm (Ariostea).."}/>}/>
                    <Route path="/storitve/sanitarna-oprema"
                           element={<ServiceDetails title={"Sanitarna oprema"}
                                                    thumbnail={SanitarnaOprema}
                                                    description={"Proizvajalci, ki se ponašajo z dolgoletno tradicijo nudi visoko kakovostne izdelave in izjemno funkcionalne produkte, ki jih poudarjata tehnologiji CeramicPlus (posebna obdelava produkta, ki omogoča še nevideno zaščito pred vodnim kamnom) in Quarly (najnovejši material za kadi in tuš kadi z izjemnimi karaktersitkami)."}/>}/>
                    <Route path="/storitve/armature"
                           element={<ServiceDetails title={"Armature"}
                                                    thumbnail={Armature}
                                                    description={"Inovativne ideje, razburljiv design in napredna tehnologija so tri glavne značilnosti znanega nemškega proizvajalca armatur za kopalnico in kuhinjo Aloys F. Dornbracht."}/>}/>
                    <Route path="/storitve/welness"
                           element={<ServiceDetails title={"Welness"}
                                                    thumbnail={Welness}
                                                    description={"Poleg produktov proizvajalcev Villeroy&Boch in Effegibi nudimo tudi popolno storitev načrtovanja, svetovanja in izvedbe ureditev prostora, namenjenega domačemu prostoru za razvajanje. Poleg vseh vrst savn nudimo tudi hidromasažne bazene, skupaj z vso pripadajočo opremo od kromoterapije do vgrajenih zvočnih sistemov.\n" +
                                                    "\n" +
                                                    "Za vse, ki sodite v generacijo dotika in se ne morete upreti designerskim skušnjavam, kot so na primer Applov I-phone, pa nudimo najnovejši že večkrat nagrajeni produkt Touch&Steam, ki predstavlja revolucijo na področju domačih turških savn."}/>}/>
                    <Route path='*' element={<NoRouteFound/>}/>
                </Routes>
            </div>
            <div className={style.stickyFooter}>
                <TriangleSeparatorTop/>
                <Partners/>
                <Footer/>
            </div>
        </div>
    )
        ;
}

export default App;
