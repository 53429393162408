import style from './ServiceList.module.scss'
import { useNavigate } from "react-router-dom";
import Service from "./Service";
import Keramika from "../assets/water-jet-cutting.webp";
import SanitaryEquipment from "../assets/sanitarna-oprema.jpeg"
import DornbrachtArmature from "../assets/dornbracht-armatura.jpeg"
import Welness from "../assets/welness2.jpeg"

export default function ServiceList() {
    const navigate = useNavigate();

    return <div className={style.serviceList}>
        {/*TODO: Move into list of properties*/}
        <Service
            onClick={() => navigate("/storitve/keramika")}
            title={"Keramika"}
            description={"Prodaja in razrez keramike. Pestra izbira različnih debelin keramike od 3mm do 14mm."}
            thumbnail={Keramika}/>
        <Service
            onClick={() => navigate("/storitve/sanitarna-oprema")}
            title={"Sanitarna oprema"}
            description={"Pestra izbira sanitarne opreme priznanih proizvajalcev."}
            thumbnail={SanitaryEquipment}/>
        <Service
            onClick={() => navigate("/storitve/armature")}
            title={"Armature"}
            description={"Armature z napredno tehnologijo in razburljivim designom."}
            thumbnail={DornbrachtArmature}/>
        <Service
            onClick={() => navigate("/storitve/welness")}
            title={"Welness"}
            description={"Popolna storitev načrtovanja, svetovanja in izvedbe ureditev prostora, namenjenega domačemu prostoru za razvajanje.."}
            thumbnail={Welness}/>
    </div>
}
