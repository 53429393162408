import style from './HeadlineImage.module.scss';
import sampleImage from '../assets/front-page-image.jpeg';

interface HeadlineImageProps {
    title: string
}

export default function HeadlineImage(props: HeadlineImageProps) {
    return <div className={style.headlineContainer}>
        <div className={style.imageContainer}
             style={{backgroundImage: `url("${sampleImage}")`}}>
        </div>
    </div>
}
