import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import React from "react";
import style from './Header.module.scss';
import AvenusLogo from '../assets/avenus-logo.svg';
import Image from "./Image";

export default function Header() {

    return <header className={style.headerContainer}>
        <Image src={AvenusLogo} clickUrl={"/"} height={70} alt={"Avenus logo"}/>
        <div className={style.menuContainer}>
            <div className={style.menuItem}><Link to="/">Home</Link></div>
            <div className={style.menuItem}><HashLink smooth to="/#services">Storitve</HashLink></div>
            <div className={style.menuItem}><HashLink smooth to="/#projects">Projekti</HashLink></div>
            <div className={style.menuItem}><HashLink smooth to="/#contact">Kontakt</HashLink></div>
        </div>
    </header>;
}
