import TitlePanel, { PanelTheme } from "../components/TitlePanel";
import HeadlineImage from "../components/HeadlineImage";
import GoogleMapPin from "../components/GoogleMapPin";
import ServiceList from "../components/ServiceList";
import ContactPanel from "../components/ContactPanel";
import ProjectList from "../components/ProjectList";
import TriangleSeparatorTop from "../components/TriangleSeparatorTop";
import style from "./Home.module.scss";

export default function Home() {
    return <div className={style.homePage}>
        <HeadlineImage title={"Water Jet Cutting Porcelain Tile"}/>
        <section id="services">
            <TitlePanel title={"Storitve"}>
                <ServiceList/>
            </TitlePanel>
        </section>
        <TriangleSeparatorTop/>
        <section id="projects">
            <TitlePanel title={"Projekti"} theme={PanelTheme.dark}>
                <ProjectList/>
            </TitlePanel>
        </section>
        <section id="contact">
            <TitlePanel title={"Kontaktirajte nas"}>
                <ContactPanel address={"Koželjeva ulica 6, 1000 Ljubljana"}
                              email={"test@gmail.com"}
                              phone={"123-456-7890"}/>
            </TitlePanel>
        </section>
        <GoogleMapPin/>
    </div>
}
